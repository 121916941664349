import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import Divider from '@solid-ui-components/Divider'

import MockupVideoPresentation from '../../../assets/caliscale-presentation.mp4'

const MockupVideo = ({
                       content: { text = [], images, buttons, form },
                       reverse
                     }) => (
  <Container>
    <video loop autoPlay muted style={{
      width: '100%',
      marginTop: -40
      //   @media (max-width: ${(props) => props.theme.screen.md}) {
      //   margin-top: -40px;
      //   width: 100%;
      // }
      //   @media (max-width: ${(props) => props.theme.screen.sm}) {
      //   width: 100%;
      //   display: none;
      // }
    }}>
      <source src={MockupVideoPresentation} type='video/mp4' />
    </video>
    <Flex sx={{ alignItems: `center`, flexDirection: [`column`, null, `column`], m: [0, -4] }}>
      <Reveal effect='fadeInDown'>
        <Box
          sx={{
            flexBasis: [`1`, `1/2`],
            alignSelf: `center`

          }}
        >
          <Reveal effect='fadeInDown'>
            {buttons && (
              <>
                <Divider space={3} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        </Box>
      </Reveal>
    </Flex>
  </Container>
)

export default WithDefaultContent(MockupVideo)
