import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import MockupVideo from '@solid-ui-blocks/MockupVideo/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithLottie/Block01'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block08'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import './_translations'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Expand your Sharetribe marketplace to the mobile market' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <Divider space='6' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <Features content={content['features']} />
      </Container>
      <Divider space='5' />
      <MockupVideo content={content['mockup-video']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.whyChooseUsContainer}>
        <WhyChooseUs content={content['why-choose-us']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={4} variant='pill' id={'main-features'}>
          <FeatureTabOne content={content['feature-tab-one']} />
          <FeatureTabTwo content={content['feature-tab-two']} reverse />
          <FeatureTabThree content={content['feature-tab-three']} />
        </Tabs>
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Pricing content={{identifier: 'pricing'}} contentTabOne={content['pricing-tab-one']} contentTabTwo={content['pricing-tab-two']} />
      <Divider space='5' />
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      {/*<Divider space='5' />*/}
      {/*<Divider space='5' />*/}
      {/*<Container variant='wide' sx={styles.testimonialsContainer}>*/}
      {/*  <Testimonials content={content['testimonials']} />*/}
      {/*  <Divider space='5' color='omegaLight' />*/}
      {/*  <Companies content={content['companies']} />*/}
      {/*</Container>*/}
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default IndexPage
